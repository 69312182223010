<template>
	<div class="border-light-grey py-2 dashboard-listing">
		<p class="mx-5 mb-1 font-level-3-bold text-uppercase">{{ label }}</p>
		<div style="max-height: 400px; min-height: 400px; height: 400px; overflow-y: scroll">
			<table width="100%" style="table-layout: fixed">
				<template v-if="rows.length">
					<tr v-for="(row, index) in rows" :key="index" class="dl-alternate">
						<td class="py-1">
							<v-layout
								v-on:dblclick="open_folder(row)"
								class="mx-4 py-1 row-file cursor-pointer border-light-grey-radius"
								:style="`color: ${row.color_code}; transition: 0.4s ease-in-out`"
							>
								<v-flex
									lg6
									md6
									sm6
									class="px-2 text-truncate my-auto font-level-3 d-flex align-items-center"
								>
									<v-icon v-if="row.type == 'folder'" large :style="`color: ${row.color_code}`">
										<template v-if="row.locked">mdi-folder-lock</template>
										<template v-else>mdi-folder</template>
									</v-icon>
									<div v-else class="file-icon small-icon py-2">
										<inline-svg :src="$assetURL(`media/mime/${row.extension}.svg`)" />
									</div>
									<show-value class="ml-2 ellipses" :object="row" object-key="name" label="name" />
									<i
										aria-hidden="true"
										class="ml-2 v-icon mdi mdi-star theme--light"
										:style="`color: ${row.color_code}; font-size: 16px;`"
										v-if="row.starred"
									></i>
									<i
										aria-hidden="true"
										class="ml-2 v-icon mdi mdi-account-group theme--light"
										:style="`color: ${row.color_code}; font-size: 16px;`"
										v-if="row.sharred"
									></i>
								</v-flex>
								<v-flex lg3 md3 sm3 class="px-2 text-truncate my-auto font-level-3 text-center">
									<show-value :object="row" object-key="owner" label="owner" />
								</v-flex>
								<v-flex lg3 md3 sm3 class="px-2 text-truncate my-auto font-level-3 text-center">
									<show-value :object="row" object-key="modified.human_format" label="modified" />
								</v-flex>
							</v-layout>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr>
						<td>
							<v-layout v-if="!loading">
								<v-flex class="my-auto font-level-3-bold py-3 text-center">No matching results</v-flex>
							</v-layout>
						</td>
					</tr>
				</template>
			</table>
		</div>
		<unlock-folder-dialog
			:unlock-folder-dialog="folder_unlock_dialog"
			:folder-unlock="folder_unlock"
			v-on:close="folder_unlock_dialog = false"
			v-on:success="folder_unlock_success($event)"
		></unlock-folder-dialog>
	</div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import JwtService from "@/core/services/jwt.service";
import UnlockFolderDialog from "@/view/components/UnlockFolderDialog";
import ShowValue from "@/view/components/ShowValue";
import { EventBus } from "@/core/event-bus/event.bus";
import ObjectPath from "object-path";
import { AES } from "crypto-js";

export default {
	props: {
		label: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			loading: false,
			selected: null,
			rows: [],
			folder_unlock: {},
			folder_unlock_dialog: false,
		};
	},
	methods: {
		folder_unlock_success(row) {
			this.folder_unlock = new Object({});
			this.folder_unlock_dialog = false;
			this.$nextTick(() => {
				const folder_id = AES.encrypt(JSON.stringify(row.id), this.$enc_key).toString();
				const folder_uuid = AES.encrypt(JSON.stringify(row.uuid), this.$enc_key).toString();
				this.$router.push({
					name: this.endpoint,
					query: {
						t: new Date().getTime(),
						folder: folder_id,
						fuuid: folder_uuid,
					},
				});
			});
		},
		open_folder(row) {
			if (this.endpoint == "bin") {
				return false;
			}

			if (this.loading) {
				return false;
			}

			if (row.locked) {
				this.folder_unlock = row;
				this.folder_unlock_dialog = true;
				return false;
			}

			let endpoint = this.endpoint;

			if (this.endpoint == "starred") {
				endpoint = "mine";
			}

			if (row.type == "folder") {
				this.filter["page-no"] = 1;
				const folder_id = AES.encrypt(JSON.stringify(row.id), this.$enc_key).toString();
				const folder_uuid = AES.encrypt(JSON.stringify(row.uuid), this.$enc_key).toString();
				this.$router.push({
					name: endpoint,
					query: {
						t: new Date().getTime(),
						folder: folder_id,
						fuuid: folder_uuid,
					},
				});
			} else {
				this.selected = row.uuid;
				this.$nextTick(() => {
					this.download_file();
				});
			}
		},
		download_file() {
			if (this.endpoint == "bin") {
				return false;
			}
			const token = JwtService.getToken();
			const url = `${this.$apiURL}files/${this.selected}/download?token=${token}`;
			window.open(url, "_blank");
		},
		get_rows() {
			/* http://127.0.0.1:8000/api/files/recent?linked-id=0&page-no=1&page-limit=50&t=1664779617123 */

			this.filter = {
				"linked-id": 0,
				"page-no": 1,
				"page-limit": 10,
				t: new Date().getTime(),
			};

			this.loading = true;

			this.$store
				.dispatch(QUERY, { url: `files/${this.endpoint}`, data: this.filter })
				.then((response) => {
					this.rows = ObjectPath.get(response, "rows", []);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	components: {
		"show-value": ShowValue,
		"unlock-folder-dialog": UnlockFolderDialog,
	},
	mounted() {
		this.get_rows();		

		EventBus.$on("refresh-listing", () => {
			this.$nextTick(() => {
				this.get_rows();
			});
		});
	},
};
</script>
